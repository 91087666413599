import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/es5/locale/es";
import en from "vuetify/es5/locale/en";
import i18n from "@/plugins/i18n";

/* ICONOS PERSONALIZADOS */
import Asignar from "@/assets/images/icons/Asignar.vue";
import AsignarConductor from "@/assets/images/icons/AsignarConductor.vue";
import AsignarEmpresa from "@/assets/images/icons/AsignarEmpresa.vue";
import AsignarVehiculo from "@/assets/images/icons/AsignarVehiculo.vue";
import EmpresaAsignada from "@/assets/images/icons/EmpresaAsignada.vue";
import VehiculoAsignado from "@/assets/images/icons/VehiculoAsignado.vue";
import ConductorAsignado from "@/assets/images/icons/ConductorAsignado.vue";
import OfertarViaje from "@/assets/images/icons/OfertarViaje.vue";
import Solicitudes from "@/assets/images/icons/Solicitudes.vue";
import Entrega from "@/assets/images/icons/Entrega.vue";
import Envio from "@/assets/images/icons/Envio.vue";
import Paradas from "@/assets/images/icons/Paradas.vue";

Vue.use(Vuetify);
console.log(i18n);

export default new Vuetify({
  lang: {
    locales: { es, en },
    current: i18n.locale,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0B0045",
        secondary: "#F05A24",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        tercery: "#5C48F7",
        neutral: "#E0E0E0",
      },
    },
  },
  icons: {
    iconfont: "fa",
    values: {
      Asignar: {
        component: Asignar,
      },
      AsignarConductor: {
        component: AsignarConductor,
      },
      AsignarEmpresa: {
        component: AsignarEmpresa,
      },
      AsignarVehiculo: {
        component: AsignarVehiculo,
      },
      EmpresaAsignada: {
        component: EmpresaAsignada,
      },
      VehiculoAsignado: {
        component: VehiculoAsignado,
      },
      ConductorAsignado: {
        component: ConductorAsignado,
      },
      Solicitudes: {
        component: Solicitudes,
      },
      OfertarViaje: {
        component: OfertarViaje,
      },
      Entrega: {
        component: Entrega,
      },
      Envio: {
        component: Envio,
      },
      Paradas: {
        component: Paradas,
      },
    },
  },
});
